import { ErrorCodes } from "@common/lib/forms.utils"
import { getQueryParams, isNullOrEmpty, recordErrorInForm } from "@common/lib/util"
import { Status } from "@common/types"

const emptyResponseErrorMessages = {
  403: "Oops! It looks like you don't have permission for this action. Please check your credentials and try again.",
  413: "Our apologies, the request seems too large to process. Could you try with a smaller request?",
  502: "Hmm, we seem to be having trouble connecting to the service. We're on it, and please try again shortly!",
  500: "We're experiencing some technical difficulties on our end. Rest assured, we're looking into it right away!",
  422: "We're experiencing some technical difficulties on our end. Rest assured, we're looking into it right away!",
}

export const fetcher = async (...args) => {
  const response = await fetch(...args)
  let parsedResponse = null
  try {
    parsedResponse = await response?.json()
  } catch (err) {
    return handleServerErrors(response.status, {})
  }
  if (!response.ok) {
    return handleServerErrors(response.status, parsedResponse)
  }
  return handleResponse(parsedResponse)
}

export const postFetcher = async (url, body, _headers, method, ...args) => {
  const headers = _headers ? _headers : { "Content-type": "application/json" }
  const response = await fetch(url, {
    method: method || "POST",
    body,
    headers,
    ...args,
  })
  let parsedResponse = null
  try {
    parsedResponse = await response?.json()
  } catch (err) {
    return handleServerErrors(response.status, {})
  }
  if (!response.ok) {
    return handleServerErrors(response.status, parsedResponse)
  }
  return handleResponse(parsedResponse)
}

export async function handleError(error, source = "unknown") {
  const errorDetails = {
    message: error.message || "An unknown error occurred (CATCH)",
    stack: error.stack || "No stack trace available",
    timestamp: new Date().toISOString(),
    source,
  }
  recordErrorInForm(errorDetails.message, errorDetails)

  return {
    data: null,
    isError: JSON.parse(JSON.stringify(error)),
  }
}

export const options = { revalidateOnFocus: false }
export const autoRefreshSWROptions = {
  revalidateOnFocus: false,
  refreshInterval: 5000,
  errorRetryCount: 2,
}

export function getFullUrl(url) {
  const environment = process.env.NODE_ENV
  const apiPrefix = process.env.NEXT_PUBLIC_API_PREFIX || "api_v2"
  const defaultPort = environment === "production" ? 9000 : 8000
  const port = process.env.SERVER_PORT || defaultPort // default port to 8000/9000, "PORT" is used by next start so using "SERVER_PORT" to use different port in local environment
  // if the network call is made in server side then use absolute url otherwise relative ulr
  return typeof window == "undefined"
    ? `http://localhost:${port}/${apiPrefix}/${url}`
    : `/${apiPrefix}/${url}`
}

/**
 * @returns {any}
 */
function handleResponse(parsedResponse) {
  const standardResponse = {
    data: null,
    status: null,
    message: null,
  }
  if (!parsedResponse) {
    return standardResponse
  } else {
    standardResponse.data = parsedResponse
    if (parsedResponse.status === Status.Otp) {
      standardResponse.status = parsedResponse.status
      standardResponse.message = getAndRecordResponseMessage(parsedResponse, false)
    } else if (
      parsedResponse.status === "FAILURE" ||
      parsedResponse.status === "UNAUTHORIZED" ||
      parsedResponse.status === "ERROR"
    ) {
      standardResponse.status = Status.Failure
      standardResponse.message = getAndRecordResponseMessage(parsedResponse)
    } else {
      standardResponse.status = Status.Success
      standardResponse.message = getAndRecordResponseMessage(parsedResponse, false)
    }
    return standardResponse
  }
}

function handleServerErrors(statusCode, parsedResponse) {
  const standardResponse = {
    data: null,
    status: Status.Failure,
    message: null,
  }
  if (typeof window !== "undefined") {
    standardResponse.message = getAndRecordResponseMessage(
      isNullOrEmpty(parsedResponse) ? { statusCode } : parsedResponse
    )
    if (statusCode === 401) {
      const { href = "" } = window.location
      const url = encodeURIComponent(href)
      const queryParams = getQueryParams(location.toString())
      if (!queryParams["url"] && !href.includes("/login")) {
        window.location.href = `/login?url=${url}` // Redirect to login
      }
    }

    if (statusCode in emptyResponseErrorMessages) {
      //this needs to be in client
      showNetworkToast(emptyResponseErrorMessages[statusCode])
    }
  }
  return standardResponse
  // throw new Error("Server error occurred")
}

function getAndRecordResponseMessage(response, isRecordMessage = true) {
  let message = null
  if (response) {
    const {
      errorCode,
      user_message,
      error_message, // 🚦 User friendly UI messages.
      internal_error_message,
      display_messages,
      statusCode,
    } = response

    // Determine the most appropriate error message to display
    if (user_message) {
      message = user_message
    } else if (error_message) {
      message = error_message
    } else if (display_messages?.overall?.errors) {
      message = !isNullOrEmpty(display_messages.overall.errors)
        ? display_messages.overall.errors
        : null
    } else if (errorCode && errorCode in ErrorCodes) {
      message = ErrorCodes[errorCode]
    } else if (statusCode && emptyResponseErrorMessages[statusCode]) {
      message = emptyResponseErrorMessages[statusCode]
    }
    // if ((message || internal_error_message) && isRecordMessage) { // as this did not log server error with no message
    if (isRecordMessage) {
      recordErrorInForm(
        message,
        ` ${
          internal_error_message
            ? `internal_error_message-${internal_error_message}`
            : response
              ? `data-${JSON.stringify(response)}`
              : `data-undefined`
        }`
      )
    }
  }

  return message
}

//exceptional usecase, keep it here to discourage calling toasts via window events elsewhere.
function showNetworkToast(message, type = "error") {
  const event = new CustomEvent("show-toast", {
    detail: { message, options: { type } },
  })
  window.dispatchEvent(event)
}
